/* eslint-disable react/no-unescaped-entities */
import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../less/cms.less'

const MerchantAgreement = () => {
  return (
    <Layout>
      <SEO title="Merchant Agreement" />
      <div className="cms-container">
        <h1 className="cms-container__title">Restaurants Terms &amp; Conditions</h1>
        <div className="cms-container__wrapper">
          <p>The agreement outlines additional terms & conditions agreed upon between the Restaurant and MealPal.</p>
          <h3>The RESTAURANT agrees to the following:</h3>
          <ul>
            <li>
              Offer meals to MealPal customers daily, Monday through Friday when the restaurant is open for business.
            </li>
            <li>
              Select one meal per day in the Merchant Portal by 5pm the day prior to the meal. If a meal isn’t selected,
              MealPal will select the meal.
            </li>
            <li>
              Set a minimum daily cap of 25 which may be lowered by MealPal at any time using its sole discretion.
            </li>
            <li>
              Display a visible MealPal sign with the QR code for all customers to scan in order to pick up their meal.
            </li>
            <li>Provide meals to MealPal customers based on the daily reservation email sent.</li>
            <li>
              Provide MealPal customers with meals of the same size and quality as would be prepared for customers
              ordering off of the regular restaurant menu. Meals that are provided at a smaller portion size than the
              normal restaurant meal portion size, as reported by MealPal customers, will not be paid for.
            </li>
            <li>MealPal has the right to modify the meal schedule at its sole discretion.</li>
            <li>
              Be responsible for and make such tax returns required by law on the meals supplied under these
              arrangements and pay for and indemnify MealPal against all of the Restaurant’s obligations to pay taxes.
            </li>
            <li>
              The restaurant will be charged a 3.5% payment handling fee. This will be deducted from the weekly payment.
            </li>
            <li>
              Merchant agrees to provide accurate and complete banking details to MealPal. If the Merchant does not
              provide payment details for over one year, they agree to relinquish these funds to MealPal.
            </li>
            <li>
              In the event that MealPal overpays the Restaurant, the Company has the right to offset the overpayment
              against future payments to the Restaurant.
            </li>
          </ul>

          <h3>Restaurant's responsibility for quality and safety: </h3>
          <ul>
            <li>
              The Restaurant is solely responsible for the quality and safety of all meals supplied to MealPal
              subscribers. It shall provide such warnings to MealPal subscribers as are appropriate, required and
              necessary in accordance with best retail food industry practices laws and regulations relating to the
              meals and their ingredients including without limitation all necessary information relating to food
              allergies.
            </li>
            <li>
              The Restaurant will hold MealPal its directors, officers and employees harmless and shall fully and
              effectively indemnify each of them from and against all and any costs claims liabilities and expenses
              received suffered or incurred whatsoever and howsoever arising by reason of the Restaurant providing meals
              to MealPal's subscribers.
            </li>
          </ul>

          <h3>OTHER Terms and Conditions:</h3>
          <ul>
            <li>The terms of the agreement shall be kept confidential, except where required by law.</li>
            <li>
              This partnership will automatically renew each year unless cancelled by either party with 30 days’ advance
              notice.
            </li>
            <li>
              The Restaurant's liability under any matter indemnified in these terms shall survive and not be affected
              by termination of the arrangements and agreement between the parties.
            </li>
            <li>
              MealPal will pay the restaurant for all meals ordered or all meals picked up via a successful QR code
              scan, whichever is greater.
            </li>
          </ul>
          <p>All terms and conditions are subject to change at any time at MealPal discretion.</p>
          <p>Last Updated 1/1/2023</p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default MerchantAgreement
